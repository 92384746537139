import type { Address } from '../../components/Article/Contact/models';
import type { RawGeoInfoFragment } from '../../gql/fragments/__generated/RawGeoInfo';
import type { Nullable } from '../../models/CustomUtilityTypes';
import { ErrorMessage } from '../../models/ErrorMessage';

export default (data: MaybeRefOrGetter<Nullable<RawGeoInfoFragment>>) => {
  const transformToSingleLineString = computed((): Nullable<string> => {
    const dataValue = toValue(data);
    if (!dataValue) {
      console.error(ErrorMessage.NO_DATA);
      return null;
    }

    const streetPart = joinNonEmptyStrings([
      dataValue.street,
      dataValue.streetNo,
    ]);
    const cityPart = joinNonEmptyStrings([dataValue.zipcode, dataValue.city]);

    return joinNonEmptyStrings([streetPart, cityPart], ', ');
  });

  const transformToAddressObject = computed((): Nullable<Address> => {
    const dataValue = toValue(data);
    if (!dataValue) {
      console.error(ErrorMessage.NO_DATA);
      return null;
    }

    return {
      street: dataValue.street ?? '',
      streetNo: dataValue.streetNo ?? '',
      zipcode: dataValue.zipcode ?? '',
      city: dataValue.city ?? '',
    };
  });

  return { transformToSingleLineString, transformToAddressObject };
};
