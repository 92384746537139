import type { BaseVideo, ImxPlatformVideo } from '../models/BaseVideo';
import type { Nullable } from '../models/CustomUtilityTypes';

export default (
  data: MaybeRefOrGetter<Nullable<ImxPlatformVideo>>
): BaseVideo | null => {
  if (isEmpty(data) && !isImxPlatformVideo(data)) {
    return null;
  }

  const dataValue = toValue(data)!;

  const videoId = extractYouTubeId(dataValue.videoIdentification);

  return {
    fig: {
      deeplink: buildThumbnail(videoId),
    },
    videoIdentification: videoId,
  };
};

function buildThumbnail(youTubeId: Nullable<string>): Nullable<string> {
  if (!youTubeId) {
    return null;
  }

  return `https://img.youtube.com/vi/${youTubeId}/mqdefault.jpg`;
}

function extractYouTubeId(data: Nullable<string>): string {
  if (isEmpty(data)) return '';

  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([^&\s]+)/;
  const match = data!.match(regex);
  return match ? match[1] : data!;
}
