import type { Address } from '../../components/Article/Contact/models';
import type { RawContactDataFragment } from '../../gql/fragments/__generated/RawContactData';
import type { Nullable } from '../../models/CustomUtilityTypes';
import { ErrorMessage } from '../../models/ErrorMessage';

export default (data: MaybeRefOrGetter<Nullable<RawContactDataFragment>>) => {
  const transformToSingleLineString = computed((): Nullable<string> => {
    const dataValue = toValue(data);
    if (!dataValue) {
      console.error(ErrorMessage.NO_DATA);
      return null;
    }

    const streetPart = joinNonEmptyStrings([
      dataValue.address?.street,
      dataValue.address?.streetNo,
    ]);
    const cityPart = joinNonEmptyStrings([
      dataValue.address?.zipcode,
      dataValue.address?.city,
    ]);

    return joinNonEmptyStrings([streetPart, cityPart], ', ');
  });

  const transformToAddressObject = computed((): Nullable<Address> => {
    const dataValue = toValue(data);
    if (!dataValue) {
      console.error(ErrorMessage.NO_DATA);
      return null;
    }

    return {
      name: dataValue.contactName ?? '',
      street: dataValue.address?.street ?? '',
      streetNo: dataValue.address?.streetNo ?? '',
      zipcode: dataValue.address?.zipcode ?? '',
      city: dataValue.address?.city ?? '',
      email: dataValue.address?.email ?? '',
      phone: dataValue.address?.phone1 ?? '',
    };
  });

  return { transformToSingleLineString, transformToAddressObject };
};
