const STATE_KEY = 'whl-instance-domain';

export default (): Ref<string> => {
  return useState<string>(STATE_KEY, (): string => {
    if (
      import.meta.client &&
      window.__IMXPLATFORM__WIDGET__?.widgetCurrentHost
    ) {
      // widget mode
      return window.__IMXPLATFORM__WIDGET__.widgetCurrentHost;
    } else {
      // standalone or storybook mode
      const url = useRequestURL();
      return url.host;
    }
  });
};
