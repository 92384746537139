import type { MaybeRefOrGetter } from 'vue';
import type { BaseImage, ImxPlatformImage } from '../models/BaseImage';
import type { BaseVideo, ImxPlatformVideo } from '../models/BaseVideo';
import type { BaseFile, ImxPlatformFile } from '../models/BaseFile';
import type { Nullable } from '../models/CustomUtilityTypes';

export default (
  data: MaybeRefOrGetter<
    Nullable<ImxPlatformImage | ImxPlatformVideo | ImxPlatformFile>
  >
) => {
  const instanceConfigRef = useWhlInstanceConfig();
  const whlModuleTypeRef = useWhlModuleType();
  const whlDomainRef = useWhlDomain();

  const toBaseImage = computed((): Nullable<BaseImage> => {
    const baseDomain = toValue(whlDomainRef);
    const instanceConfig = toValue(instanceConfigRef);
    const lowerCaseModuleType = toValue(whlModuleTypeRef)?.toLocaleLowerCase();
    const dataValue = toValue(data);

    if (isEmpty(dataValue) || !isImxPlatformImage(dataValue)) {
      if (instanceConfig?.customFallbackImages === true) {
        return {
          deeplink: `https://${baseDomain}/assets/images/fallback/${instanceConfig.instanceId}/${lowerCaseModuleType}.svg`,
        };
      } else {
        return {
          deeplink: `https://${baseDomain}/assets/images/fallback/${lowerCaseModuleType}.svg`,
        };
      }
    }
    return convertImxPlatformImage(dataValue);
  });

  const toBaseVideo = computed((): Nullable<BaseVideo> => {
    const dataValue = toValue(data);
    if (isEmpty(dataValue) || !isImxPlatformVideo(dataValue)) {
      return null;
    }

    return convertImxPlatformVideo(dataValue);
  });

  const toBaseFile = computed((): Nullable<BaseFile> => {
    const dataValue = toValue(data);
    if (isEmpty(dataValue) || !isImxPlatformFile(dataValue)) {
      return null;
    }
    return null;
    //return convertImxPlatformFile(dataValue);
  });

  return { toBaseImage, toBaseVideo, toBaseFile };
};
