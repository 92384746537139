import type { BaseImage, ImxPlatformImage } from '../models/BaseImage';
import type { Nullable } from '../models/CustomUtilityTypes';

export default (
  data: MaybeRefOrGetter<Nullable<ImxPlatformImage>>
): BaseImage | undefined => {
  if (isEmpty(data) && !isImxPlatformImage(data)) {
    return undefined;
  }

  const dataValue = toValue(data)!;

  const image: BaseImage = dataValue as BaseImage;

  // calculate relative focal point
  if (
    dataValue.focalPointX &&
    dataValue.imageWidth &&
    dataValue.focalPointY &&
    dataValue.imageHeight
  ) {
    image.focalPoint = {
      x: Number(dataValue.focalPointX) / Number(dataValue.imageWidth),
      y: Number(dataValue.focalPointY) / Number(dataValue.imageHeight),
    };
  }

  // remove information about absolute focal point (from imx.Platform)
  delete (image as ImxPlatformImage).focalPointX;
  delete (image as ImxPlatformImage).focalPointY;

  return image;
};
